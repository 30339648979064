
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="true"
    append-to-body
    style="text-align:left;"
    width="1000px"
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" inline :rules="ruleValidate" :label-width="'150px'">
        <el-form-item label="所属单位" prop="companyId">
          <el-select-tree
            :options="companyList"
            v-model="formModel.companyId"
            size="medium"
            height="200"
            width="300px"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="所属平台" prop="platform">
          <el-select v-model="formModel.platform" clearable width="300px">
            <el-option v-for="(item,index) in platformList" 
            :label="item.name" :value="item.value" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备序列号" prop="deviceSerial">
          <el-input placeholder="设备序列号" v-model="formModel.deviceSerial" style="width:300px;">
            <!-- <el-button slot="append" icon="el-icon-search" @click="queryDeviceInfo">查询</el-button> -->
          </el-input>
        </el-form-item>
        <el-form-item label="设备名称" prop="deviceName">
          <el-input v-model="formModel.deviceName" placeholder="请输入设备名称" style="width:300px"></el-input>
        </el-form-item>
        <template v-if="formModel.platform=='ys'">
          <el-form-item label="通道号" prop="channelNo">
            <el-input v-model="formModel.channelNo" placeholder="请输入通道号" style="width:300px"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="validCode">
            <el-input v-model="formModel.validCode" placeholder="请输入验证码" style="width:300px"></el-input>
          </el-form-item>
          <el-form-item label="设备账户号" prop="account">
            <el-input v-model="formModel.account" placeholder="请输入账户号" style="width:300px"></el-input>
          </el-form-item>
          <el-form-item label="设备密码" prop="password">
            <el-input v-model="formModel.password" placeholder="请输入密码" style="width:300px"></el-input>
          </el-form-item>
        </template>
        <el-form-item label="所在房间" prop="roomAddress">
          <el-input v-model="formModel.roomAddress" placeholder="请输入所在房间" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="绑定人员编号" prop="personId">
          <el-input v-model="formModel.personId" placeholder="请输入绑定人员编号" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="contactPhone">
          <el-input v-model="formModel.contactPhone" placeholder="请输入联系电话" style="width:300px"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import cameraInfoApi from "@/api/base/cameraInfo";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";
import dataDictionaryApi from "@/api/sys/dataDictionary";

export default {
  props: ["businessKey", "title"],
  computed: {
      ruleValidate (){
        var rules = null;

        if(this.formModel.platform =='aliyun'){
          rules = {
            deviceSerial: [
              { required: true, message: "设备序列号不能为空", trigger: "blur" }
            ],
            deviceName: [
              { required: true, message: "设备名称不能为空", trigger: "blur" }
            ],
            companyId: [
              { required: true, message: "单位不能为空", trigger: "blur" }
            ]
          };
        }
        else{
          rules = {
            deviceSerial: [
              { required: true, message: "设备序列号不能为空", trigger: "blur" }
            ],
            deviceName: [
              { required: true, message: "设备名称不能为空", trigger: "blur" }
            ],
            companyId: [
              { required: true, message: "单位不能为空", trigger: "blur" }
            ],
            channelNo: [
              { required: true, message: "通道号不能为空", trigger: "blur" }
            ],
            validCode: [
              { required: true, message: "验证码不能为空", trigger: "blur" }
            ],
            account: [
              { required: true, message: "账户不能为空", trigger: "blur" }
            ],
            password: [
              { required: true, message: "密码不能为空", trigger: "blur" }
            ]
          };
        }

        return rules;
      }
  },
  data() {
    return {
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {},
      companyList: [],
      platformList: []
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    queryDeviceInfo() {
      this.loading = true;

      
      if(self.formModel.classify==null){
        self.$message({
          message: "清先选择设备分类!",
          type: "warning",
        });
      }

      cameraInfoApi
        .queryDeviceInfo(this.formModel.deviceSerial,this.formModel.classify)
        .then((response) => {
          this.loading = false;
          var jsonData = response.data;

          if (jsonData.result) {
            this.formModel.deviceName = jsonData.data.deviceName;
            this.formModel.channelNo = 1;
            this.formModel.status = jsonData.data.status;
          }
        });
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return cameraInfoApi.add(self.formModel);
            } else {
              return cameraInfoApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              //self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  created() {
    companyInfoApi.treeList().then((response)=>{
      var jsonData = response.data;

      if (jsonData.result) {
        this.companyList = jsonData.data;
      }
    });

    dataDictionaryApi.findByCatalogName({
      "catalogName" : "摄像头云平台"
    }).then(response => {
      var jsonData = response.data;
      this.platformList = jsonData.data;
    });
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return cameraInfoApi.create();
      } else {
        return cameraInfoApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
  components: {
    "el-select-tree": SelectTree,
  },
};
</script>
import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/base/cameraInfo/pageList", formData);
}

function create(){
  return request.get(constant.serverUrl + "/base/cameraInfo/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/base/cameraInfo/edit/" + id);
}

function queryDeviceInfo(deviceSerial){
  return request.get(constant.serverUrl + `/base/cameraInfo/queryDeviceInfo?deviceSerial=${deviceSerial}`);
}

function queryLiveAddressUrl(data){
  var formData = new FormData();

  for(var name in data){
    formData.append(name, data[name]);
  }

  return request.post(constant.serverUrl + "/base/cameraInfo/queryLiveAddressUrl",formData);
}

function add(formModel){
  return request.post(constant.serverUrl + "/base/cameraInfo/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/base/cameraInfo/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function refresh(id){
  return request.post(constant.serverUrl + "/base/cameraInfo/refresh/" + id);
}

function remove(id){
  return request.post(constant.serverUrl + "/base/cameraInfo/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/base/cameraInfo/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function bindClass(formModel){  
  return request.post(constant.serverUrl + "/base/cameraInfo/bindClass", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function bindClassList(id){  
  return request.get(constant.serverUrl + `/base/cameraInfo/bindClassList/${id}`);
}

export default {
  pageList,create,edit,add,update,remove,
  batchRemove,queryDeviceInfo,queryLiveAddressUrl,refresh,
  bindClass,bindClassList
}

<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
  >
    <div>
        <el-input placeholder="输入查询班级名称" v-model="className" style="width:300px;">
            <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
    </div>
    <el-table
      :data="filterData"
      v-loading="loading"
      height="400"
      stripe
    >
      <el-table-column prop="className" label="班级名称"></el-table-column>
      <el-table-column prop="top" label="是否绑定" width="180">
        <template slot-scope="{row}">
            <el-switch  v-model="row.enabled" 
            active-color="#13ce66" inactive-color="#ff4949"
            @change="handleBindClass(row)"
            ></el-switch>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import cameraInfoApi from "@/api/base/cameraInfo";

export default {
    props: ["cameraInfo"],
    data(){
        return {
            title: "绑定班级",
            showDialog: true,
            loading: false,
            tableData: [],
            className: ''
        }
    },
    computed: {
        filterData() {
            if(this.className.length==0){
                return this.tableData;
            }
            else{
                return this.tableData.filter(item=>{
                    return item.className.indexOf(this.className)!=-1;
                });
            }
        }
    },
    methods: {
        queryClass() {

        },
        closeDialog() {
            this.$emit("close", false);
        },
        handleBindClass(row) {
            cameraInfoApi.bindClass(row).then(response=>{
                var jsonData = response.data;

                if(!jsonData.result){
                    this.$message.warning(jsonData.message + "");
                }
            });
        }
    },
    mounted() {
        this.loading = true;

        cameraInfoApi.bindClassList(this.cameraInfo.id).then(response=>{
            this.loading = false;

            var jsonData = response.data;

            if(jsonData.result){
                this.tableData = jsonData.data;
            }
            else{
                this.$message.warning(jsonData.message + "");
            }
        });
    }
}
</script>
<style>

</style>
<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">基础数据</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/base/cameraInfo">摄像头管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb> 
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="设备序列号" prop="deviceSerial">
        <el-input type="text" size="mini" v-model="queryModel.deviceSerial"></el-input>
      </el-form-item>
      <el-form-item label="设备名称" prop="deviceName">
        <el-input type="text" size="mini" v-model="queryModel.deviceName"></el-input>
      </el-form-item>
      <el-form-item label="在线状态" prop="status">
        <el-select size="mini" v-model="queryModel.status">
          <el-option label="不在线" value="0"></el-option>
          <el-option label="在线" value="1"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="设备分类" prop="classify">
         <el-select size="mini" v-model="queryModel.classify">
          <el-option label="试用" value="1"></el-option>
          <el-option label="付费" value="2"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="所在单位" prop="companyId">
        <el-select-tree
          size="mini"
          :options="companyList"
          v-model="queryModel.companyId"
          height="200"
        ></el-select-tree>
      </el-form-item>
      <el-form-item label="所在房间" prop="roomAddress">
        <el-input type="text" size="mini" v-model="queryModel.roomAddress"></el-input>
      </el-form-item>
      <el-form-item label="所属平台" prop="platform">
        <el-select v-model="queryModel.platform" size="mini" style="width:100px;" clearable>
          <el-option v-for="(item,index) in platformList" 
          :label="item.name" :value="item.value" :key="index"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd">新增</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length==0"
        @click="handleBatchDelete"
      >删除选中项</el-button>
    </el-row>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="picUrl"
        label="截图"
        width="180"
      >
        <template slot-scope="{row}">
          <a :href="row.picUrl+'?x-oss-process=image/resize,m_lfit,w_1024'" target="_blank" v-if="row.picUrl!=''">
            <img :src="row.picUrl+'?x-oss-process=image/resize,m_fill,w_100'" class="capture-image"/>
          </a>
        </template>
      </el-table-column>
      <el-table-column
        prop="companyName"
        sort-by="company_name"
        label="所属单位"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="platformN"
        label="所属平台"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="deviceSerial"
        sort-by="device_serial"
        label="设备序列号"
        sortable="custom"
        width="150"
      ></el-table-column>
      <el-table-column prop="status" label="在线状态" width="100">
        <template slot-scope="{row}">
          <span v-if="row.status==1" style="color:green;">在线</span>
          <span v-else>不在线</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="classify" label="设备分类" width="100">
        <template slot-scope="{row}">
          <span v-if="row.classify=='1'">试用</span>
          <span v-if="row.classify=='2'">付费</span>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="channelNo"
        label="通道号"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="deviceName"
        label="设备名称"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="roomAddress"
        label="所在房间"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="validCode"
        label="验证码"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="personId"
        label="人员ID"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="contactPhone"
        label="联系电话"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="updateTime"
        label="更新时间"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" fixed="right" width="200">
        <template slot-scope="{row}">
          <el-link type="primary" :underline="false" @click="queryLiveAddressUrl(row)">直播地址</el-link>-
          <el-link type="primary" :underline="false" @click="handleEdit(row)">编辑</el-link>-
          <el-link type="primary" :underline="false" @click="handleRefresh(row)">刷新</el-link>-
          <el-link type="primary" :underline="false" @click="handleDelete(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <cameraInfo-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></cameraInfo-detail>
    <el-dialog
      :visible.sync="showLiveAddressDialog"
      title="直播地址"
      :modal-append-to-body="false"
      style="text-align:left;"
      width="850px"
      @closed="handleCloseLive()"
    >
      <video 
        ref="myPlayer"
        autoplay
        controls
        playsInline 
        webkit-playsinline
        width="800" height="450"
      >
      </video>
      <div>
        <label>直播地址:</label>
        <span v-html="liveAddressUrl"></span>
      </div>
      <template v-if="pushAddress!=null">
      <div>
        <label>推流地址:</label>
        <span v-html="pushAddress"></span>
      </div>
      </template>
    </el-dialog>
    <cameraInfo-bindClass
      v-if="showBindModal"
      :cameraInfo="selectedRow"
      @close="onBindModalClose"
    ></cameraInfo-bindClass>
  </div>
</template>
<script>
import Constant from "@/constant";
import CameraInfoDetail from "./cameraInfo-detail";
import CameraInfoBindClass from "./cameraInfo-bindClass";
import cameraInfoApi from "@/api/base/cameraInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import SelectTree from "@/components/SelectTree";
import companyInfoApi from "@/api/base/companyInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import Hls from "hls.js";

export default {
  name: "baseCameraInfoList",
  data() {
    var self = this;

    return {
      queryModel: {
        deviceSerial: "",
        deviceName: "",
        status: "",
        companyId: "",
        classify: ""
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      companyList: [],
      showLiveAddressDialog: false,
      liveAddressUrl: "",
      player: null,
      selectedRow: {},
      showBindModal: false,
      platformList: [],
      pushAddress: null
    };
  },
  methods: {
    handleBindClass(row) {
      this.selectedRow = row;
      this.showBindModal = true;
    },
    handleCloseLive() {
      if(this.player!=null){
        this.player.pause();
      }
    },
    queryLiveAddressUrl(row) {
      this.loading = true;

      cameraInfoApi
        .queryLiveAddressUrl({
          id:row.id,
          deviceSerial: row.deviceSerial,
          channelNo: row.channelNo == null ? 0 : row.channelNo,
          expireTime: 1800,
        })
        .then((response) => {
          this.loading = false;
          var jsonData = response.data;

          if (jsonData.result) {
            this.showLiveAddressDialog = true;
            
            this.liveAddressUrl = jsonData.data.hdAddress.replace("http:","https:");
            this.pushAddress = jsonData.data.pushAddress;

            this.$nextTick(()=>{
              this.player = this.$refs["myPlayer"];

              var hls = new Hls();
              hls.loadSource(this.liveAddressUrl);
              hls.attachMedia(this.player);

              hls.on(Hls.Events.MANIFEST_PARSED,()=>{
                  this.player.play();
              });
            //   this.player = new window.EZUIKit.EZUIPlayer('myPlayer');

            //   // 日志
            //   this.player.on('log', (str)=>{
            //     console.log(str);
            //   });
            })
          } else {
            this.$message.error(jsonData.message + "");
          }
        });
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("deviceSerial", self.queryModel.deviceSerial);
      formData.append("deviceName", self.queryModel.deviceName);
      formData.append("status", self.queryModel.status);
      formData.append("companyId", self.queryModel.companyId);
      formData.append("classify", self.queryModel.classify);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      cameraInfoApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.sortBy;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          cameraInfoApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        cameraInfoApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    handleRefresh(record) {
      var self = this;

      self.loading = true;

      cameraInfoApi.refresh(record.id).then(function (response) {
        self.loading = false;

        var jsonData = response.data;

        if (jsonData.result) {
          // var index = self.tableData.indexOf(record);
          // self.tableData.splice(index, 1);
          self.changePage(self.pageIndex);

          self.$message({
            type: "success",
            message: "刷新成功!",
            showClose: true
          });
        }
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    onBindModalClose() {
      this.showBindModal = false;
    }
  },
  created() {
    companyInfoApi.treeList().then((response)=>{
      var jsonData = response.data;
      if (jsonData.result) {
        this.companyList = jsonData.data;
      }
    });

    dataDictionaryApi.findByCatalogName({
      "catalogName" : "摄像头云平台"
    }).then(response => {
      var jsonData = response.data;
      this.platformList = jsonData.data;
    });
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "cameraInfo-detail": CameraInfoDetail,
    "el-select-tree": SelectTree,
    "cameraInfo-bindClass": CameraInfoBindClass
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}

.capture-image{
  width:160px;
  height: 90px;
  object-fit: container;
}
</style>